.select-wrapper {
  &.MuiFormControl-root {
    min-height: 26px;
    min-width: 100px;

    .selects {
      width: 100%;
      @include d-flex-v-center;
      min-height: 32px;

      .MuiCircularProgress-root {
        min-width: 20px;
        margin: 0 0.5rem;
      }

      .dropdown-icon-wrapper {
        @include d-inline-flex-center;
        // color: $c-primary;
        position: absolute;
        right: 0;
        pointer-events: none;

        @include rtl {
          left: 0;
          right: initial;
        }

        &.is-with-end-adornment {
          right: 1.5rem;

          @include rtl {
            left: 1.5rem;
            right: initial;
          }
        }

        font-size: $fz-22px;
        margin: 0 0.45rem;

        &::before {
          font-size: $fz-22px;
        }
      }

      .MuiSelect-select {
        background-color: $search-box-bg-color !important;

        // min-height: 26px;
        font-size: $fz-14px;
        @include texts-truncate;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 2rem !important;
        padding-left: 0.5rem !important;

        @include rtl {
          padding-left: 2rem !important;
          padding-right: 0.5rem !important;
        }

        &[aria-expanded='true'] {
          +input+.mdi {
            &::before {
              transform: scale(-1);
            }
          }
        }

        &.Mui-disabled {
          background-color: $c-gray-secondary !important;
          cursor: not-allowed;
          pointer-events: initial;
          color: $c-white !important;

          +input+.mdi {
            &::before {
              color: $c-white !important;
            }
          }
        }
      }

      .MuiSelect-icon {
        @include rtl {
          right: initial;
          left: 0;
        }
      }

      color: $c-primary;
    }

    // ---------------------------------------------------------------------------
    .unselects {
      width: 100%;
      @include d-flex-v-center;
      min-height: 32px;

      .MuiCircularProgress-root {
        min-width: 20px;
        margin: 0 0.5rem;
      }

      .dropdown-icon-wrapper {
        @include d-inline-flex-center;
        // color: $c-primary;
        position: absolute;
        right: 0;
        pointer-events: none;

        @include rtl {
          left: 0;
          right: initial;
        }

        &.is-with-end-adornment {
          right: 1.5rem;

          @include rtl {
            left: 1.5rem;
            right: initial;
          }
        }

        font-size: $fz-22px;
        margin: 0 0.45rem;

        &::before {
          font-size: $fz-22px;
        }
      }

      .MuiSelect-select {
        // min-height: 26px;
        font-size: $fz-14px;
        @include texts-truncate;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 2rem !important;
        padding-left: 0.5rem !important;

        @include rtl {
          padding-left: 2rem !important;
          padding-right: 0.5rem !important;
        }

        &[aria-expanded='true'] {
          +input+.mdi {
            &::before {
              transform: scale(-1);
            }
          }
        }

        &.Mui-disabled {
          background-color: $c-gray-secondary !important;
          cursor: not-allowed;
          pointer-events: initial;
          color: $c-white !important;

          +input+.mdi {
            &::before {
              color: $c-white !important;
            }
          }
        }
      }

      .MuiSelect-icon {
        @include rtl {
          right: initial;
          left: 0;
        }
      }

      color: $c-gray-secondary;
    }

    &.theme-action-buttons {
      border-radius: 2rem;

      &.c-white {
        .selects {

          .MuiSelect-select,
          .dropdown-icon-wrapper {
            color: $c-white;
          }
        }

        .unselects {

          .MuiSelect-select,
          .dropdown-icon-wrapper {
            color: $c-white;
          }
        }
      }

      &.bg-secondary {
        .selects {
          .MuiSelect-select {
            &:focus {
              background-color: $c-secondary;
            }
          }
        }

        .unselects {
          .MuiSelect-select {
            &:focus {
              background-color: $c-secondary;
            }
          }
        }
      }

      .select-body-wrapper {
        width: 100%;
      }

      .MuiInput-underline:before {
        display: none;
      }

      .MuiInput-underline.Mui-focused:after {
        display: none;
      }

      .selects {
        .MuiSelect-select {
          &:focus {
            background-color: $c-white;
          }

          // @include d-inline-flex-center;
          // padding-right: 0.5rem !important;
          // padding-left: 0.5rem !important;
          border-radius: 2rem;
        }

        .MuiSelect-icon {
          display: none;
        }
      }

      .unselects {
        .MuiSelect-select {
          &:focus {
            background-color: $c-white;
          }

          // @include d-inline-flex-center;
          // padding-right: 0.5rem !important;
          // padding-left: 0.5rem !important;
          border-radius: 2rem;
        }

        .MuiSelect-icon {
          display: none;
        }
      }
    }

    &.theme-default {
      width: 100%;
      border-radius: 2rem;

      &.c-white {
        .selects {
          .MuiSelect-select {
            color: $c-white;
          }
        }

        .unselects {
          .MuiSelect-select {
            color: $c-white;
          }
        }
      }

      &.bg-secondary {
        .selects {
          .MuiSelect-select {
            &:focus {
              background-color: $c-secondary;
            }
          }
        }

        .unselects {
          .MuiSelect-select {
            &:focus {
              background-color: $c-secondary;
            }
          }
        }
      }

      .select-body-wrapper {
        width: 100%;
      }

      .MuiInput-underline:before {
        display: none;
      }

      .MuiInput-underline.Mui-focused:after {
        display: none;
      }

      .selects {
        border: 1px solid #f0f0f0;
        border-radius: 0.5rem;
        background-color: $search-box-bg-color !important;

        &.Mui-focused,
        &:hover {
          &:not(.Mui-disabled) {
            border-color: $c-gray-secondary;
          }
        }

        .MuiSelect-select {
          &:focus {
            background-color: $c-white;
          }

          // @include d-inline-flex-center;
          // padding-right: 0.5rem !important;
          // padding-left: 0.5rem !important;
          border-radius: 2rem;
        }

        .MuiSelect-icon {
          display: none;
        }
      }

      .unselects {
        border: 1px solid #f0f0f0;
        border-radius: 0.5rem;
        background-color: $search-box-bg-color !important;

        &.Mui-focused,
        &:hover {
          &:not(.Mui-disabled) {
            border-color: $c-gray-secondary;
          }
        }

        .MuiSelect-select {
          &:focus {
            background-color: $c-white;
          }

          // @include d-inline-flex-center;
          // padding-right: 0.5rem !important;
          // padding-left: 0.5rem !important;
          border-radius: 2rem;
        }

        .MuiSelect-icon {
          display: none;
        }
      }
    }

    &.theme-solid {
      &.with-start-andorment {
        .MuiInputBase-adornedStart>.mdi {
          color: $c-primary;
          margin-left: 0.5rem;

          @include rtl {
            margin-right: 0.5rem;
            margin-left: initial;
          }
        }

        &.theme-dark {
          .MuiInputBase-adornedStart>.mdi {
            color: $c-white;
          }
        }
      }

      .label-wrapper {
        padding: 0 0.5rem 0.25rem;
        color: $c-primary;
        width: 100%;
      }

      .select-body-wrapper {
        @include d-flex;
        flex: 0 0 auto;
        position: relative;
        width: 100%;

        .MuiInput-underline.Mui-error:after {
          display: none;
        }

        .selects {
          border-radius: 2rem;
          // background-color: $c-white;
          background-color: $search-box-bg-color !important;

          width: 100%;
          min-height: 35px;
          border: 1px solid #e6e6e6;

          &.MuiInput-underline::before,
          &.MuiInput-underline.Mui-focused:after {
            display: none;
          }

          &:hover:not([disabled]):not(.Mui-disabled) {
            border-color: #dbf5ee;
          }

          &.Mui-focused:not([disabled]):not(.Mui-disabled) {
            border-color: $c-primary !important;
            box-shadow: 0 0 2px 2px rgba($c-primary, 0.3);
          }

          &.Mui-error {
            border-color: $c-error !important;
            box-shadow: 0 0 2px 2px rgba($c-error, 0.5);
          }

          .MuiSelect-icon:not(.Mui-disabled) {
            fill: $c-primary;
          }

          .MuiSelect-select.MuiSelect-select {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            min-height: 20px;
            border-radius: 50px;
            color: $c-primary;
            background-color: $search-box-bg-color !important;

          }

          .MuiSelect-select {
            background-color: $search-box-bg-color !important;
          }
        }

        .unselects {
          border-radius: 2rem;
          background-color: $c-white;
          width: 100%;
          min-height: 35px;
          border: 1px solid #e6e6e6;

          &.MuiInput-underline::before,
          &.MuiInput-underline.Mui-focused:after {
            display: none;
          }

          &:hover:not([disabled]):not(.Mui-disabled) {
            border-color: #dbf5ee;
          }

          &.Mui-focused:not([disabled]):not(.Mui-disabled) {
            border-color: $c-primary !important;
            box-shadow: 0 0 2px 2px rgba($c-primary, 0.3);
          }

          &.Mui-error {
            border-color: $c-error !important;
            box-shadow: 0 0 2px 2px rgba($c-error, 0.5);
          }

          .MuiSelect-icon:not(.Mui-disabled) {
            fill: $c-primary;
          }

          .MuiSelect-select.MuiSelect-select {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            min-height: 20px;
            color: $c-primary;
          }

          .MuiSelect-select {
            background-color: transparent !important;
          }
        }
      }
    }

    &.theme-underline {
      border-radius: 0;
      width: 100%;

      .selects {
        .MuiSelect-select {
          color: $c-black;
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
      }

      .unselects {
        .MuiSelect-select {
          color: $c-black;
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
      }

      &.select-filled {
        .selects {
          .MuiSelect-select {
            color: $c-primary;
          }
        }

        .unselects {
          .MuiSelect-select {
            color: $c-primary;
          }
        }
      }

      .MuiInput-underline:not(.Mui-disabled)::before {
        border-bottom-color: $c-gray-lighter;

        &.Mui-focused {
          border-bottom-color: $c-primary;
        }

        &:hover {
          border-bottom-color: $c-gray-primary;
        }
      }
    }

    &.theme-transparent {
      border-radius: 0;
      width: 100%;

      .selects {
        .MuiSelect-select {
          color: $c-black;
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
      }

      .unselects {
        .MuiSelect-select {
          color: $c-black;
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
      }

      &.select-filled {
        .selects {
          .MuiSelect-select {
            color: $c-primary;
          }
        }

        .unselects {
          .MuiSelect-select {
            color: $c-primary;
          }
        }
      }

      .MuiInput-underline:not(.Mui-disabled)::before {
        border-bottom-color: $c-gray-lighter;

        &.Mui-focused {
          border-bottom-color: $c-primary;
        }

        &:hover {
          border-bottom-color: $c-gray-primary;
        }
      }

      .MuiInput-underline:after {
        display: none;
      }

      .MuiInput-underline:not(.Mui-disabled)::before {
        border-bottom: 0;
      }
    }

    &.hide-arrow {
      .selects {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }

      .unselects {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }

      .dropdown-icon-wrapper {
        display: none !important;
      }
    }

    &.over-input-select {
      .selects {
        min-height: 30px !important;
      }

      .unselects {
        min-height: 30px !important;
      }

      .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
        padding: 0 1.5rem 0 0.5rem !important;
        background-color: $search-box-bg-color !important;
        border-radius: 50px;

        @include rtl {
          padding: 0 0.5rem 0 1.5rem !important;
        }
      }
    }
  }
}

// MuiMenuItem-root
.select-menu-wrapper {
  .MuiMenu-paper {
    border-radius: 1rem;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;

    .MuiMenu-noOptions {
      color: $c-black-dark;
    }

    .MuiMenu-listbox {
      .MuiMenu-option {
        // background-color: transparent;
        color: $c-black-dark;
      }
    }

    .MuiMenuItem-root {
      font-size: $fz-14px !important;
    }
  }

  //   &.theme-dark {
  //     .MuiMenu-paper {
  //       background-color: $c-default-dark !important;
  //       border: 1px solid $c-default-dark !important;
  //       .MuiMenu-noOptions {
  //         color: $c-white;
  //       }
  //       .MuiMenu-listbox {
  //         .MuiMenu-option {
  //           &[aria-selected='true'] {
  //             background-color: #4b0f7c !important;
  //           }
  //           color: $c-white;
  //         }
  //       }
  //     }
  //   }
  &.with-backdrop {
    background-color: raba(0, 0, 0, 0.3);
  }
}