.TeamDialog {
  .MuiDialogTitle-root {
    border-bottom: 1px solid $c-gray-dark !important;
  }
  .MuiDialogContent-root {
    padding: 25px 4rem 1rem;
    min-width: 50rem;
    min-height: 20rem;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiOutlinedInput-root {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
  }
  .MuiAutocomplete-endAdornment {
    display: none;
  }
  .list-title {
    font-size: $fz-18px;
  }
  .left-list {
    position: relative;
    .spinner-wrapper {
      position: relative;
    }
    .text-field-wrapper {
      width: 100%;
    }
    width: 150%;
    .list-users {
      margin-top: 1rem;
      border-radius: 11px;
      border: 1px solid $c-gray-light;
      height: 15rem;
      overflow-y: auto;
    }
  }
  .right-list {
    width: 150%;
    .list-users-right {
      margin-top: 2rem;
      border-radius: 11px;
      border: 1px solid $c-gray-light;
      height: 15rem;
      overflow-y: auto;
      .star-color {
        color: rgb(218, 218, 0);
      }
    }
  }
  .assign-button {
    width: 80%;
    display: flex;
    justify-content: center;
    .MuiButton-label {
      text-transform: capitalize;
    }
  }
  .dialog-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.delete-team-dialog {
  .MuiTypography-h6 {
    font-size: $fz-16px;
  }
}
