.login-view {
  background-image: url('./../../../assets/images/GIF_images/propx-bg3.gif');
  // width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-clip: content-box;
  background-size: cover;
  background-position: center;

  .login-logo {
    width: 100%;
    max-height: 135px;
  }
  .login-header{
    align-self: flex-start;
  }

  @media (max-width : 1600px) {
    .login-form-wrapper{
      align-items: flex-start;
    }
    .login-logo {
      margin-top: 10px;
      width: 90%;
    }
  }

  * {
    margin: 0;
  }

  .form-wrapper {
    label {
      padding-left: 0;
    }
  }

  .heading-1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .heading-1,
  .login-label,
  p {
    color: $c-white !important;

  }

  .heading-2 {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
  }

  .blurrd-bg {
    height: 100vh;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.04);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-view-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 75%;
    min-height: 75%;
    height: fit-content;
    background-color: $c-white;
    border-radius: 32px 40px 40px 32px;
    position: relative;
  }

  .login-form-wrapper {
    svg {
      margin-bottom: 40px;
    }
  }

  .login-form-container {
    max-width: 360px;
  }

  .login-form-bottom {
    .MuiFormControlLabel-label {
      font-size: 12px !important;
    }

    .MuiCheckbox-root {
      padding: 0;
      padding-right: 8px;

      &:hover {
        background-color: unset !important;
      }

    }

    .i-unchecked {
      border-radius: 4px;
      border: 1px solid #D0D5DD;
    }

    .mdi-check {
      background-color: var(--c-primary) !important;
    }

    .Mui-checked {
      color: #635235;
    }




  }

  .login-btn {
    display: flex;
    height: 37px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid $c-white;
    background: $c-primary;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: unset;

    span {
      color: #FFF;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &:hover {
      border: 1px solid $c-white;
      background: lighten($color: $c-primary, $amount: 15);
    }
  }

  .back-btn {
    span {
      color: #475467;
      font-size: 10px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        color: #344054;
      }
    }



    img {
      width: 14px;
      height: 14px;
    }
  }

  .login-footer {
    width: 100%;
    padding: 20px 32px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .slide-show-wrapper {
    background-image: url('./../../../assets/images/hirx/login-side-image.jpg');
    background-color: black;
    height: 101%;
    width: 101%;
    position: relative;
    bottom: .5%;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: cover;
    background-position: center;
  }

  .forgot-user-banner {
    padding: 12px 16px;
    margin-bottom: 24px;
    margin-top: 32px;
    border-bottom: 1px solid #EAECF0;

    .user-img {
      margin-right: 12px;
      width: 40px;
      height: 40px;
      max-width: 40px;
      min-width: 40px;
      border: 0.75px solid rgba(222, 222, 222, 0.8);
    }

    .user-info {
      :first-child {
        color: #344054;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }

      :last-child {
        color: #475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

    }

  }

  .login-text-btn {
    color: #635235;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: var(--c-primary);
    }
  }

  .OTP-input {
    margin-bottom: 0px;

    &.filled {
      .MuiInputBase-input {
        border: 2px var(--c-primary) solid;
        color: var(--c-primary);
      }
    }

    .text-field-wrapper {
      min-height: 64px;
    }

    .MuiInputBase-input {
      min-height: 64px;
      padding: 6px !important;
      color: var(--c-primary) !important;
      text-align: center;
      font-size: 48px !important;
      font-weight: 500;
      border-radius: 10px;
      border: 1px #D0D5DD solid;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 64px !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-bottom: 24px;
    }

    .MuiInputBase-root {
      border-radius: 10px !important;
      border: unset !important;
    }

    .MuiInputBase-input:focus {
      border: 2px var(--c-primary) solid;
      color: var(--c-primary);
    }

    .MuiInputBase-input:focus {
      outline: 5px solid #E3DED4 !important;
    }

  }

  .input-wrapper.theme-default .text-field-wrapper .inputs .MuiInputBase-input {
    padding: 6px !important;
  }

  .login-content {
    position: relative;
    z-index: 5;
  }

}


@media (max-width: 1440px) {
  .login-view {
    .login-form-wrapper svg {
      margin-bottom: 12px;
    }

    .login-form-bottom {
      margin-bottom: 0.8rem !important;
      margin-top: 0.8rem !important;
    }

    .login-footer {
      padding: 12px 32px;

      p {
        font-size: 10px;
      }
    }

    .login-view-wrapper {
      min-width: 88%;
      min-height: 88%;
    }

    .heading-1 {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 12px;
    }

    .heading-2 {
      font-size: 16px;
      margin-bottom: 1.5rem !important;
    }

    .login-text-btn {
      font-size: 12px;
    }

    .login-form-wrapper svg {
      margin-bottom: 12px;
    }

    .login-form-container {
      width: 100%;
    }

    .OTP-input {
      .text-field-wrapper {
        min-height: 40px !important;
        margin-inline: 2px;
      }

      .MuiInputBase-input {
        max-height: 40px !important;
        margin-inline: 2px;
        min-height: 40px !important;
        margin-inline: 2px;
        width: 42px !important;
        margin-inline: 2px;
      }
    }

    .back-btn {
      margin-block: 1rem !important;
    }

  }
}


//mobile
@media (max-width: 800px) {
  .login-view {
    .login-view-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      min-height: 85%;
      border-radius: 32px;
    }

    .slide-show-wrapper {
      display: none;
    }

  }

  h2,
  label,
  p {
    color: $c-white;
  }
}