.text-nowrap {
  white-space: nowrap;
}

.texts,
.texts-header,
.texts-form {
  font-size: $fz-default;

  &.gray-primary-bold,
  &.primary-bold {
    font-weight: bold;
  }

  &.primary-bold {
    color: $c-primary;
  }

  &.gray-primary-bold {
    color: $c-black;
  }
}

.texts-form {
  padding: 0 1rem 0.5rem;
  color: $c-black;
}

.texts-truncate {
  @include texts-truncate;
}

.texts-header {
  font-size: $fz-45px;
}

.capitalize{
  text-transform: capitalize !important;
}
