.Html-Preview-Dialog {
  .MuiDialog-container {
    min-width: 900px !important;
  }

  .MuiDialogContent-root {
    min-height: 70vh !important;
  }

  .template-wrapper {
    max-height: 65vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
}