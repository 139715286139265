.modules-wrapper::-webkit-scrollbar {
  display: none;
}

#alert-dialog-slide-title {
  h2 {
    // width: 169px;
    // height: 32px;

    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: bolder !important;
    font-size: $fz-20px !important;
    line-height: 32px !important;
    /* identical to box height, or 160% */

    // display: flex;
    // align-items: center;
    // letter-spacing: 0.15px !important;

    color: $c-black !important;
  }
}

.MuiDialogTitle-root {
  // border-bottom: 1px solid $c-gray-dark !important;
  padding-left: 2rem;
  padding: 16px 24px 0 !important;
}

.branch-dialog-wrapper {
  .mdi::before {
    font-size: $fz-20px;
  }

  .MuiPaper-root {
    max-width: 65%;
  }

  .document-images-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  .MuiDialogContent-root {
    padding: 1rem;
  }

  .MuiTab-root {
    margin: 0 2% !important;

    span {
      /* Branch */

      // width: 53px;
      // height: 25px;
      font: inherit;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 800;
      font-size: $fz-14px;
      line-height: 24px;
      /* identical to box height, or 175% */

      // display: flex;
      // align-items: center;
      // text-align: center;
      // letter-spacing: 0.39998px;
      // text-transform: capitalize;
    }

    span:focus {
      color: var(--c-primary);
    }
  }

  .dialog-content-wrapper {
    width: 100%;
    @include d-flex;
    flex-wrap: wrap;
    border-top: none !important;

    .dialog-content-item {
      width: 33%;
      margin-bottom: 0.5rem;

      @include phones-max {
        width: 100%;

        .rotationCriteriaDialog-dialog-wrapper .dialog-content-wrapper .dialog-content-item {
          padding: 15px;
        }
      }

      padding: 0 0.5rem;

      .reminder-wrapper {
        display: inline-flex;
        align-items: flex-end;
        // display: grid;
        width: 100%;

        // grid-template-rows: repeat(1, auto);
        // grid-template-columns: repeat(3, auto);
        .section-to {
          margin: 0.5rem;
          padding: 0.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          letter-spacing: 0px;
          color: #3a3a3c;
          opacity: 1;
        }

        .input-range {
          width: 50%;
        }
      }

      .Requierd-Color::after {
        content: "*";
        color: $c-red;
      }
    }

    .title-wrapper {
      @include d-flex;
      margin-bottom: 0.5rem;
      padding: 0 0.5rem;

      .title-text {
        font-weight: $fw-simi-bold;
      }
    }
  }

  .Financial-dialog-content-wrapper {
    width: 100%;
    display: flex;
    border-top: none !important;

    .modules-wrapper {
      width: 100%;
      max-width: 50%;
      padding: 2% 0;
      max-height: 850px;
      overflow-y: auto;

      .module-wrapper {
        padding: 3% 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .module-title {
          height: 28px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: bold;
          font-size: $fz-14px;
          line-height: 28px;
          color: $c-black;
        }

        .selectedModule {
          border: 1px solid var(--c-primary) !important;
        }

        .module-details {
          display: flex;
          margin: 2%;
          border: 1px solid #e6e6e6;
          border-radius: 15px;
          padding: 2% 0 2% 2%;
          position: relative;

          @include rtl {
            padding: 2% 2% 2% 0;
          }

          line-height: 20px;
          height: 100px;
          width: 87%;

          .col1 {
            display: flex;
            flex-direction: column;
            padding: 2%;
            width: 100%;
            height: 100px;

            div {
              font-size: $fz-14px;
              line-height: 20px;
              display: flex;
              flex-direction: row;
              display: flex;
              flex-wrap: wrap;

              span:first-child {
                font-weight: 600;
                white-space: nowrap;
              }

              span:nth-child(2) {
                white-space: nowrap;
                width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
              }
            }

            .defualt {
              margin: 3% 0;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: $fz-14px;
              line-height: 20px;
              width: fit-content;
              color: var(--c-primary);
            }

            .selected-defualt:hover {
              color: $c-black;
            }
          }

          .col2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 1 100%;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            max-width: 45px;

            .delete-btn {
              .mdi {
                color: #a72323 !important;
              }

              border-color: #e6e6e6;
            }

            .dublicat-btn {
              color: var(--c-primary);
              border-color: #e6e6e6;
              margin: 5rem;
            }

            .actions {
              @include d-flex-column;
              margin: 0 0 0.5rem;
              min-width: initial;
              width: 45px;
              border-radius: 1rem 0 0 1rem;

              @include rtl {
                right: initial;
                left: 0;
                border-radius: 0 1rem 1rem 0;
              }
            }
          }
        }
      }
    }

    .virtical-line {
      border-right: 1px solid #ebe7e7;
      height: 850px;
      margin: 1% 0;
    }

    .detail-wrapper-dialog {
      padding: 2%;
      width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-height: 600px;

      .detail-header-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;

        .selected-module-title {
          font-size: $fz-14px;
          color: #475467;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: $fz-14px;
          line-height: 20px;
          color: var(--c-primary);
        }

        .detail-header-title {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 435px;

          span {
            height: 28px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: bold;
            font-size: $fz-14px;
            line-height: 28px;
            color: $c-black;
          }
        }
      }

      .dialog-content-item {
        max-width: 435px;
      }
    }
  }

  .capitalizeTextEn {
    text-transform: capitalize;
  }

  .invalid {
    border-color: #f44336 !important;
    border: 1px solid;
    border-radius: 0.5rem;
  }

  .GalleryShowThemeComponent {
    justify-content: unset;
  }

  .GalleryShowThemeComponent .Files-uplded {
    position: absolute;
    padding: 0px;
    height: fit-content;
    width: 130px;
    display: block;
    left: 148px;
    bottom: 75px;
  }

  .uploader-outer-card {
    min-height: fit-content;
    max-width: unset;
    max-height: unset;
    display: block;
    padding: 18px;
    padding-bottom: 10px;
    padding-top: 26px;
  }

  .counter-text {
    display: none;
  }

  .GalleryShowThemeComponent .uploader-card {
    width: 130px;
    height: 85px;
    margin: 0;
  }

  .GalleryShowThemeComponent .select-card:hover {
    width: unset;
    height: unset;
  }

  .GalleryShowThemeComponent .select-card {
    width: 130px;
    height: 85px;
  }

  .mdi:before {
    font-size: $fz-20px;
    margin-top: 3px;
  }

  .GalleryShowThemeComponent .Fab-contenar .MuiFab-root {
    min-height: 18px;
  }

  .GalleryShowThemeComponent .Fab-contenar .MuiFab-sizeSmall {
    width: 18px;
    height: 18px;
  }

  .GalleryShowThemeComponent .Fab-contenar .icon {
    top: 3.8rem;
    left: 6.4rem;
  }

  .uploader-wrapper {
    width: unset;
  }

  .item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin-left: 4%;
  }

  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    border: 1px solid $c-gray-secondary;

    &:not(.contacts-information-wrapper) {
      margin: 1rem 0.5rem;
    }

    &.b-0 {
      border-width: 0;
    }

    .title-section {
      padding: 0 0.5rem;
      font-weight: $fw-simi-bold;
      font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }
}

.moduel-popOver-wrapper {
  .MuiPaper-root {
    max-width: 100px;
    border: 2px solid #ebe7e7;
    min-height: 125px;
  }
}


@media only screen and (max-width: 3000px) and (min-width: 1170px) {
  .branch-dialog-wrapper {
    .MuiPaper-rounded {
      width: 1280px !important;
      height: 800px !important;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .branch-dialog-wrapper {
    .Financial-dialog-content-wrapper {
      flex-wrap: wrap !important;
    }
  }
}

.confirmation-message {
  .mdi::before {
    font-size: 60px;
    color: var(--c-primary);
  }

  .confirm-msg {
    font-size: 18px;
  }

  .confirm-msg-details {
    color: gray;
    font-size: 14px;
  }
}

.item-space {
  margin: 2px 4px 5px 5px;
}

.active-chip {
  background-color: #2ecc71 !important;
  color: white !important;
}

.expired-chip {
  background-color: #e74c3c !important;
  color: white !important;
}