.ReportPdfView {
  .ReportPdfView-PAGE {
    @media print {
      .aReport-Fab:after {
        content: none !important;
      }
      .Report-Fab[href]:after {
        content: none !important;
      }
      .Report-Fab {
        display: none;
      }

      .mdi:before {
        display: none !important;
      }

      .mdi {
        display: none !important;
        content: none !important;
      }
    }
    .Report-Fab {
      position: fixed;
      right: 5rem;
      z-index: 10;
      top: 1rem;
    }
    @media print {
      @page {
        size: 8.27in 11.69in;
        margin: 0.5cm;
      }
      @media print {
        @page {
          size: 8.27in 15.4in !important;
          margin: 1cm 1cm 1cm 1cm !important;
        }
      }
    }
    body {
      direction: rtl;
    }
    .pt {
      padding-top: 4mm;
    }
    .pl {
      padding-left: 4mm;
    }
    .pr {
      padding-right: 4mm;
    }
    .pb {
      padding-bottom: 4mm;
    }
    @page {
      size: A4 portrait;
      .pt,
      .pl,
      .pr,
      .pb {
        padding: 0px !important;
      }
    }
    .sheet {
      display: flex;
      flex-direction: column;
    }
    .body_cont {
      flex-grow: 1;
    }

    @page {
      margin: 0;
    }
    body {
      margin: 0;
    }
    .sheet {
      margin: 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      page-break-after: always;
    }

    /** Paper sizes **/
    body.A3 .sheet {
      width: 297mm;
      height: 419mm;
    }
    body.A3.landscape .sheet {
      width: 420mm;
      height: 296mm;
    }
    body.A4 .sheet {
      width: 210mm;
      height: 296mm;
    }
    body.A4.landscape .sheet {
      width: 297mm;
      height: 209mm;
    }
    body.A5 .sheet {
      width: 148mm;
      height: 209mm;
    }
    body.A5.landscape .sheet {
      width: 210mm;
      height: 147mm;
    }
    body.letter .sheet {
      width: 216mm;
      height: 279mm;
    }
    body.letter.landscape .sheet {
      width: 280mm;
      height: 215mm;
    }
    body.legal .sheet {
      width: 216mm;
      height: 356mm;
    }
    body.legal.landscape .sheet {
      width: 357mm;
      height: 215mm;
    }

    /** Padding area **/
    .sheet.padding-10mm {
      padding: 10mm;
    }
    .sheet.padding-15mm {
      padding: 15mm;
    }
    .sheet.padding-20mm {
      padding: 20mm;
    }
    .sheet.padding-25mm {
      padding: 25mm;
    }

    /** For screen preview **/
    @media screen {
      .A44 {
        background: #e0e0e0;
      }
      .sheet {
        position: relative;
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        margin: 5mm auto;
      }
    }

    /** Fix for Chrome issue #273306 **/
    @media print {
      body.A3.landscape {
        width: 420mm;
      }
      body.A3,
      body.A4.landscape {
        width: 297mm;
      }
      body.A4,
      body.A5.landscape {
        width: 210mm;
      }
      body.A5 {
        width: 148mm;
      }
      body.letter,
      body.legal {
        width: 216mm;
      }
      body.letter.landscape {
        width: 280mm;
      }
      body.legal.landscape {
        width: 357mm;
      }
    }
  }
}
