.AssignTeamsView-wrapper {
  margin: 0;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  .list-users-right-wrapper {
    min-height: 15rem;
    max-height: 15rem;
    overflow: auto;
    background-color: #fafafa;
    padding: 0.5rem;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
    .star-color {
      color: rgb(218, 218, 0);
    }

    .DeleteForeverIconUserDialog {
      color: rgba(249, 8, 8, 0.54);
      .MuiIconButton-root {
        color: rgba(249, 8, 8, 0.54);
      }
    }

    .list-users-right {
    }
  }

  .list-users-left-wrapper {
    background-color: #fafafa;
    padding: 0.5rem;
    border: 1px solid #d0d0d0;
    overflow: auto;
    border-radius: 15px;
    max-height: 13rem;
    min-height: 13rem;
    .list-users-left {
    }
  }
  .my-team
  {
    
$color1: #369;
$color2: darken($color1,20);

%title-left,
%title-center {
  border-radius: 6px;
  margin: 29px 0px 13px 0px;
	color:#fff;
	background:#ccc;
	position:relative;
	height:30px;
	text-transform:capitalize;
	.title {
		font-size:18px;
		position:relative;
		top:-10px;
		height:50px;
		line-height:50px;
		display:inline-block;
		background:$color1;
		color:#fff;
		font-weight:400;
		&:before,&:after {
			content:'';
			position:absolute;
			height:0;
			width:0;
			top:0;
			z-index:2;
		}
	}
	.tx {
		&:before,&:after {
			content:'';
			display:block;
			position:absolute;
			height:0;
			width:0;
			z-index:1;
		}
	}	
}

%title-left {
	padding-left:80px;

	.title {
		float:left;
		text-shadow:-2px -2px 1px rgba(0,0,0,.4);
		
		&:before {
			border-top:0px solid transparent;
			border-right:50px solid $color1;
			border-bottom: 50px solid transparent;
			border-left:0px solid transparent;
			left:-50px;
		}
		&:after {
			border-top:50px solid transparent;
			border-right:0 solid transparent;
			border-bottom: 0 solid transparent;
			border-left:50px solid $color1;
			right:-50px;
		}
	}

	.tx {
		&:before {
			border-top:10px solid transparent;
			border-right:16px solid transparent;
			border-bottom: 10px solid $color2;
			border-left:30px solid transparent;
			top:-10px;
			left:-80px;
		}
		&:after {
			border-top:10px solid $color2;
			border-right:30px solid transparent;
			border-bottom: 10px solid transparent;
			border-left:16px solid transparent;
			bottom:-10px;
			right:-80px;
		}
	}
}

%title-center {
	text-align:center;
	
	.title {
		height:45px;
		line-height: 45px;
		text-shadow:0 -2px 1px rgba(0,0,0,0.4);
	
		&:before {
			border-top:0px solid transparent;
			border-right:30px solid $color1;
			border-bottom: 45px solid transparent;
			border-left:0px solid transparent;
			left:-30px;
		}
		&:after {
			border-top:0px solid transparent;
			border-right:0 solid transparent;
			border-bottom: 45px solid transparent;
			border-left:30px solid $color1;
			right:-30px;
		}
	}

	.tx {
		&:before {
			border-top:10px solid transparent;
			border-right:8px solid transparent;
			border-bottom: 10px solid $color2;
			border-left:20px solid transparent;
			top:-10px;
			left:-50px;
		}
		&:after {
			border-top:10px solid transparent;
			border-right:20px solid transparent;
			border-bottom: 10px solid $color2;
			border-left:8px solid transparent;
			top:-10px;
			right:-50px;
		}
	}
}

.type1 {
	@extend %title-left;	
}

.type2 {
	@extend %title-center;	
}

  }


  .foundlogodiv {
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    background-color: white;
    font-weight: bold;
    border-radius: 17px;
    font-size: initial;
    .notfoundlogo {
      width: 12rem;
    }
  }
}
