.candidates-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 20px;
}
.candidate-card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
  .cards-wrapper {
    padding: 1rem 1.5rem;
  }
}

.body-title-wrapper {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
  .body-title {
    word-break: break-all;
  }
}

.created-on {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.details-section {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}

.details-item {
  font-size: 0.95rem;
  line-height: 1.4;
  color: #333;
  margin: 0.4rem 0;

  strong {
    font-weight: bold;
    color: #0a3a6e;
  }
}

.languages-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0.5rem;

  .language-item {
    background-color: #e8f4f8;
    color: #007bff;
    padding: 0.2rem 0.6rem;
    border-radius: 0.5rem;
    font-size: 0.85rem;
  }
}

.cards-footer-wrapper {
  margin-top: auto;
  padding: 0.5rem;
  border-top: 1px solid #0a3a6e;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: baseline;

  .btns {
    color: #007bff;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e8f4f8;
    }
  }
}
