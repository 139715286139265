.WorkingHoursConfirm_Dialog {

  .fz-14 {
    font-size: 14px;
  }

  .fz-12 {
    font-size: 12px;
  }

  .Dialog_Content {
    width: 32rem;

    .btns {

      .mdi:before {
        font-size: 25px;
      }

      &.solid {
        border: 1px solid #3b72d9 !important;
        background: #3b72d9 !important;
      }

      &.outlined-colored {
        border: 1px solid #3b72d9 !important;
        color: #3b72d9 !important;
      }
    }
  }

}