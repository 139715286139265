$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;
$ident: 20px;
$left: -($ident);
$right: -(-$ident);
$border-color: #8e8e93;

.TreeTeamView-groups-wrapper {
  overflow: auto;
  margin: 1rem;
  max-height: calc(100vh - 250px);
  min-height: 40rem;
  background-image: linear-gradient(263deg, #b0abab1f 10%, #00000000 10%),
    linear-gradient(#00000003 10%, #00000000 10%);
  background-size: 17px 12px;
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.isParent:last-child:before {
  border: 0 !important;
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.isParent:first-child:after {
  border: 0 !important;
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.isParent:last-child:after {
  width: 25% !important;
  border-right: 1px solid $border-color;
  height: 14px;
  border-top-right-radius: 7px;
  @include rtl {
    width: 25% !important;
    border-left: 1px solid $border-color;
    border-right: 0px solid $border-color;
    height: 14px;
    border-top-right-radius: 0px;
    border-top-left-radius: 7px;
  }
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.filetree:last-child:after {
  width: 21px !important;
  border-bottom: 1px solid #8e8e93 !important;
  border-left: 1px solid #8e8e93 !important;
  height: 40px;
  left: -21px;
  top: -2px;
  border-bottom-left-radius: 7px;
  border-right: 0px solid #8e8e93;

  @include rtl {
    width: 20px !important;
    border-right: 1px solid #8e8e93 !important;
    border-left: 0px solid #8e8e93 !important;
    height: 29px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 7px;
  }
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.filetree:first-child:before {
  border-top-left-radius: 7px;
  top: 25px;
}
.TreeTeamViewTreeView .genealogy-tree .wtree li.isParent:first-child:before {
  border-top-left-radius: 7px;
  @include rtl {
    border-top-left-radius: 0px;
    border-top-right-radius: 7px;
  }
}
.TreeTeamViewTreeView {
  $linecolor: #c7c7cc;
  .genealogy-tree {
    &.filetree {
      display: grid;
      justify-content: center;
      width: 100%;
    }
    ul:first-child {
      display: flex;
      flex: 1 1 100%;
      &.filetree {
        display: block;
      }
    }
    ul {
      &.more {
        justify-content: flex-start;
      }
      margin-left: 35px;
      @include rtl {
        margin-left: 0;
        margin-right: 5px;
      }
    }
    .wtree {
      li {
        list-style-type: none;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -30px;
          left: -20px;
          border-left: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          width: 21px;
          height: 57px;
          z-index: -1;
          @include rtl {
            left: 20px;
            right: -20px;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        &:after {
          position: absolute;
          content: '';
          top: 21px;
          left: $left;
          border-left: 1px solid $border-color;
          //  border-top: 1px solid $border-color;
          width: 20px;
          height: 100%;

          @include rtl {
            left: 12px;
            right: 0;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        &.isParent :last-child:after {
          border-left: 0px solid $border-color !important;
          position: absolute;
          content: '';
          top: -1px;
          left: 0;
          border-left: 1px solid $border-color;
          border-top: 0px solid $border-color;
          width: 50%;
          height: 100%;
          @include rtl {
            left: 0px;
            right: 0px;
            border-left: 0px solid $border-color;
            border-right: 0px solid $border-color;
          }
        }

        &.isParent :last-child :before {
          /////
          border-bottom-left-radius: 8px;

          @include rtl {
            left: 20px;
            right: 0px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 0px;
          }
        }

        &.isParent:after {
          border-left: 0px solid $border-color !important;
          position: absolute;
          content: '';
          top: -0;
          left: 0;
          border-left: 1px solid $border-color;
          border-top: 1px solid $border-color;
          width: 50%;
          height: 100%;

          @include rtl {
            right: 0;
            border-left: 0px solid $border-color;
            border-right: 0px solid $border-color;
          }
        }
        &.filetree:after {
          border-top: 0px solid $border-color !important;
        }
        &.isParent:before {
          right: auto;
          left: 50%;
          border-left: 1px solid $border-color;
          content: '';
          position: absolute;
          top: 0;
          right: 50%;
          border-bottom: 0;
          border-top: 1px solid $border-color;
          width: 50%;
          height: 18px;

          @include rtl {
            left: auto;
            right: 50%;
            border-left: 0px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        &.filetree:before {
          right: auto;
          left: 50%;
          border-left: 1px solid #8e8e93 !important;
          position: absolute;
          content: '';
          top: 19px;
          left: -21px;
          border-left: 1px solid #8e8e93;
          border-top: 1px solid #8e8e93;
          width: 40px;
          height: 124%;
          border-bottom: 4;
          @include rtl {
            left: auto;
            right: 0%;
            border-left: 0px solid $border-color !important;
            border-right: 1px solid $border-color;
          }
        }
      }
    }
    .wtree {
      display: flex;
      justify-content: center;
    }
  }
  .team-view-tree-wrapper {
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      border-bottom: 2px solid #c4c4c4 !important;
    }
    margin-top: 13px;
    .team-item-detailes {
      position: relative;
      border-radius: 11px;
      display: flex;
      justify-content: center;
      color: $c-black;
      border: 1px solid $border-color;
      position: relative;
      display: inline-block;
      margin-right: 20px;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 16rem;
      max-width: 20rem;
      &.is-open {
        border: 2px solid $c-gray-dark;
      }
      .team-name-wrapper {
        .team-name {
          font-size: $fz-14px;
        }
      }

      .item-actions {
        display: flex;
        .MuiIconButton-root {
          width: 2rem;
          height: 2rem;
        }
      }
      .MuiAccordionDetails-root {
        padding: 0;
        padding-bottom: 1rem;
      }
      .tree-item {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        background-color: #dfdfdf47;
        border-bottom: 1px solid #36363617;
        &.searched-node {
          background-color: #9db5f8bf;
          border-radius: 4px;
          color: #201e1e;
        }
        // &.active {
        //
        // }
        .MuiButtonBase-root .MuiAccordionSummary-root .Mui-expanded {
          border-bottom: 2px solid red !important;
        }
      }

      .delete-icon {
        .mdi {
          color: $c-danger;
        }
      }
      .item-name {
        min-width: 9rem;
        display: flex;
        width: 52%;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
      }
      .MuiAccordionDetails-root {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.tree-node-actions {
  margin-top: -1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  width: 17rem;
  z-index: 1;
  .expand-action {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .MuiIconButton-root {
      width: 1.5rem;
      height: 1.5rem;
      background-color: #4972d2;
      color: white;
    }
  }
  .add-action {
    z-index: 2;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .MuiIconButton-root {
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #c7c7cc;
      background-color: #ebf1fb;
    }
  }
}
