// Start Table Actions Tooltip
.table-actions-wrapper {
  position: absolute;
  min-width: 100px;
  max-height: 37px;
  border-radius: 1rem 0 0 1rem;
  background-color: $c-gray-primary;
  @include rtl {
    border-radius: 0 1rem 1rem 0;
  }
  .btns-icon {
    margin: 0 0.25rem;
  }
  z-index: 1;
  @include d-inline-flex-center;
  padding: 0 0.25rem;
  &.theme-original {
    background-color: $c-primary;
  }
}

// End Table Actions Tooltip
// Start Table
.table-responsive {
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.table-wrapper {
  &.MuiTable-root {
    .MuiTableCell-root {
      border-bottom: 0;
      font-family: $ff-default;
      @include rtl {
        font-family: $ff-defaultAR !important;
      }
      @include rtl {
        text-align: right;
      }
    }
    .MuiTableHead-root {
      .MuiTableSortLabel-root:hover {
        color: lighten(#016186,40%);
      }
      .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: lighten(#016186, 50%);
      }
      .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
        .MuiTableSortLabel-icon {
        color: lighten(#016186, 50%);
      }
      .MuiTableRow-head {
        .MuiTableCell-head {
          background-color: $header-footer-bg-color;
          color: $c-white;
          font-size: $fz-table-header;
          min-width: 150px;

          &:first-child {
            border-top-left-radius: 1rem;

            @include rtl {
              border-top-right-radius: 1rem;
              border-top-left-radius: 0;
            }
          }

          &:last-child {
            border-top-right-radius: 1rem;

            @include rtl {
              border-top-right-radius: 0;
              border-top-left-radius: 1rem;
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        white-space: nowrap;
        .MuiTableCell-body {
          height: 32px;
          background-color: $c-white;
          color: $font-color-primary;
          font-size: $fz-table-body;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 500px;
        }

        &:nth-child(even) {
          .MuiTableCell-body {
            background-color: $search-box-bg-color;
          }
        }

        &:hover,
        &.table-row-overlay {
          .MuiTableCell-body {
            background-color: #e8f0ff !important;
          }
        }
      }
    }
    .MuiTableFooter-root {
      .MuiTableRow-footer {
        .MuiTableCell-footer {
          background-color: $c-gray-lighter;
          color: $c-black-light;
          font-size: $fz-table-footer;
        }
      }
    }
    &.theme-original {
      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            background-color: $c-primary;
            color: $c-white;
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          white-space: nowrap;

          .MuiTableCell-body {
            background-color: $c-white;
            color: $c-black;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 500px;
          }

          &:nth-child(even) {
            .MuiTableCell-body {
              background-color: $c-gray-lightest;
            }
          }

          &:hover,
          &.table-row-overlay {
            .MuiTableCell-body {
              background-color: $c-blue-lightest;
            }
          }
        }
      }

      .MuiTableFooter-root {
        .MuiTableRow-footer {
          .MuiTableCell-footer {
            background-color: $c-blue-lighter;
            color: $c-white;
            font-size: $fz-table-footer;
          }
        }
      }
    }
  }
}
//  Start Table Pagination (original)
.pagination-wrapper {
  background-color: $c-white;
  border-top: 2px solid $c-gray-lighter;
  color: $c-blue-lighter;
  border-radius: 0 0 1rem 1rem;

  .MuiTypography-root {
    color: $c-blue-lighter;
  }

  .MuiTablePagination-toolbar {
    padding: 0.25rem 0.5rem;
    min-height: unset;
  }

  .select-wrapper {
    background-color: $c-gray-lightest;
    border-radius: 1rem;
    margin: 0 0.5rem;
    overflow: hidden;
    min-width: 70px;
    color: $c-primary;

    path {
      fill: $c-primary;
    }

    .MuiSelect-select {
      width: 100%;
      @include d-flex-v-center-h-between;

      @include rtl {
        padding-left: 24px;
        padding-right: 0.5rem;
      }
    }

    .MuiSelect-icon {
      @include rtl {
        right: initial;
        left: 0;
      }
    }
  }

  .btns-icon {
    &.theme-transparent {
      color: $c-primary;
    }
  }
}

.MuiTablePagination-root {
  .MuiTablePagination-actions {
    svg {
      @include rtl {
        transform: scaleX(-1);
      }
    }
  }
}

.TheScrollFunParent {
  width: 100%;
  overflow-x: auto;
}

.table-capsule {
  padding: 4px 12px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}
