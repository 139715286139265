// ======================================================== Start Fonts
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: AcuminConcept;
  font-display: swap;
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: TheSansArabic;
  font-display: swap;
  src: url('../fonts/TheSansArabic-SemiBold.otf');
}

body,
main {
  margin: 0;
}

a {
  text-decoration: none;
}

// ======================================================== End Fonts

// ======================================================== Start Plugins Override
.MuiLinearProgress-indeterminate,
.MuiTabs-indicator {
  z-index: 1;
}

// ======================================================== End Plugins Override
// ======================================================== Start Default
.label-wrapper {
  padding: 0 0.5rem 0.25rem;
  color: $c-black;
  width: 100%;
  font-size: $fz-default;
  font-weight: $fw-default;
  font-family: $ff-default;

  @include rtl {
    font-family: TheSansArabic !important;
  }

  margin-bottom: 0;
}

input[type='checkbox'],
input[type='radio'] {
  margin-top: 0 !important;
}

[role='img'],
.icons {
  background-repeat: no-repeat;
  display: inline-flex;
}

.icons {
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: 16px 16px;

  &.i-folder-white {
    background-size: 14px 14px;
  }
}

.mdi {
  line-height: 100%;

  &:before {
    line-height: 100%;
    font-size: $fz-19px;
  }

  &.mdi-chevron-right,
  &.mdi-chevron-left,
  &.mdi-menu-right,
  &.mdi-menu-left,
  &.mdi-chevron-double-right {
    @include rtl {
      transform: scale(-1);
    }
  }

  &.mdi-magnify {
    @include rtl {
      transform: rotateZ(90deg);
    }
  }

  //&.mdi-close {
  //  color: $c-red;
  //}
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex: 1 1 auto;
}

#root {
  min-height: 100%;
}

// ======================================================== End Default
// ======================================================== Start Global Assets
// Start Buttons

// End Buttons

// Start Dialogs
.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      background-color: $c-white;

      &.MuiPaper-rounded {
        border-radius: 1rem;
      }
    }

    .MuiDialogActions-root {
      padding: 0.5rem;

      .btns {
        margin: 0 0.5rem 0.5rem;
      }
    }
  }
}

.dialog {
  .dialog-btn-close {
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 10px;

    @include rtl {
      right: unset;
      left: 10px;
    }
  }
}

.dialogmap {
  .MuiPaper-rounded {
    border-radius: 1rem;
    width: 609px !important;

    @include phones-max() {
      width: auto !important;
    }
  }

  .dialog-btn-close {
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 10px;

    @include rtl {
      right: unset;
      left: 10px;
    }
  }
}

// End Dialogs
// Start Links
.links {
  text-decoration: underline;
  color: $c-blue-lighter;

  &:hover {
    text-decoration: underline;
    color: $c-primary;
  }
}

// End Links
// Start Badges
.badges {
  .MuiBadge-badge {
    background-color: $c-red;
    color: $c-white;

    &.MuiBadge-anchorOriginTopRightRectangle {
      top: -25%;

      @include rtl {
        left: 0;
        right: initial;

        &:not(.MuiBadge-invisible) {
          transform: scale(1) translate(-50%, -50%);
        }
      }
    }

    &.MuiBadge-badge {
      height: 12px;
      min-width: 12px;
      padding: 0.1rem;
      font-size: $fz-10px;
      line-height: 12px;
    }
  }
}

// End Badges
// Start Avatars
.avatars-wrapper {
  &.MuiAvatar-root {
    height: 60px;
    width: 60px;
    max-width: 60px;
    min-width: 60px;

    &.theme-small {
      width: 100%;
      height: 100%;
      font-size: 100%;
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
    }

    background-color: $c-gray-dark;
    color: $c-white;
  }
}

// End Avatars
// Start Cards
.cards {
  display: inline-flex;
  flex-direction: column;
  flex: 0 1 auto;
  min-width: 300px;
  min-height: 150px;
  box-shadow: $bs-secondary;
  background-color: $c-white;
  border-radius: 0.75rem;

  .card-header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: $bg-gradient-primary-right;
    border-radius: 0.75rem 0.75rem 0 0;
    font-size: $fz-11px;
    color: $c-white-dark;
    min-height: 60px;
    padding: 0.5rem;

    .texts-large {
      color: $c-white;
      font-size: $fz-default;
      font-weight: 500;
    }
  }

  .card-content {
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .card-footer {
    padding: 0.25rem;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 0 0 0.75rem 0.75rem;
  }
}

// End Cards
// Start Separators
.separator-h {
  position: relative;
  min-height: 1px;
  width: 100%;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: $c-gray-lighter;
  }

  &.s-gray-primary {
    &:after {
      background-color: $c-gray-primary;
    }
  }

  &.s-gray-light {
    &:after {
      background-color: $c-gray-light;
    }
  }

  &.s-gray-lightest {
    &:after {
      background-color: $c-gray-lightest;
    }
  }
}

.separator-v {
  position: relative;
  height: 100%;
  min-width: 1px;

  &.p-25px {
    height: calc(100% + 25px);
  }

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    background-color: $c-gray-lighter;

    @include rtl {
      left: initial;
      right: 0;
    }
  }

  &.s-gray-primary {
    &:after {
      background-color: $c-gray-primary;
    }
  }

  &.s-gray-dark {
    &:after {
      background-color: $c-gray-dark;
    }
  }

  &.s-primary {
    &:after {
      background-color: $c-primary;
    }
  }

  &.s-reverse {
    &:after {
      right: 0;
      left: initial;

      @include rtl {
        left: 0;
        right: initial;
      }
    }
  }

  &.s-h-25px {
    min-height: 25px;
  }
}

.Requierd-Color::after {
  content: ' *';
  color: $c-red;
}

// End Separators
// Start Collapses
.collapses {
  &.absolute-collapse {
    position: absolute;
    right: 0;
    z-index: 2;

    @include phones-max() {
      position: fixed;
      right: 10px;
      max-width: 300px;
      margin-top: 15px;

      @include rtl {
        right: initial;
        left: 10px;
      }
    }

    @include rtl {
      right: initial;
      left: 0;
    }

    &.is-centered {
      right: unset !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}

// End Collapses
// Start Circles
.circle-ripple {
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 100%;
  background-color: $c-lavender;
  color: $c-info;
  @include d-inline-flex-center;
}

// End Circles

// Start Datepicker
.picker-wrapper {
  &.MuiFormControl-root {
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiButtonBase-root {
    padding: 0.25rem;
  }

  &.hide-button {
    .MuiButtonBase-root {
      display: none;
    }
  }
}

// End Datepicker
// Start Select
// .select-wrapper {
//   &.MuiFormControl-root {
//     min-height: 26px;
//     background-color: $c-white;
//     min-width: 150px;

//     .selects {
//       .MuiSelect-select {
//         min-height: 26px;
//         padding-top: 0;
//         padding-bottom: 0;
//         padding-right: 24px;
//         padding-left: 0.5rem;
//         @include d-flex;
//         @include flex-v-center;

//         @include rtl {
//           padding-left: 24px;
//           padding-right: 0.5rem;
//         }
//       }

//       .MuiSelect-icon {
//         @include rtl {
//           right: initial;
//           left: 0;
//         }
//       }

//       color: $c-primary;
//     }

//     &.theme-action-buttons {
//       border-radius: 2rem;
//       &.c-white {
//         .selects {
//           .MuiSelect-select {
//             color: $c-white;
//           }
//         }
//       }
//       &.bg-secondary {
//         .selects {
//           .MuiSelect-select {
//             &:focus {
//               background-color: $c-secondary;
//             }
//           }
//         }
//       }
//       .MuiInput-underline:before {
//         display: none;
//       }

//       .MuiInput-underline.Mui-focused:after {
//         display: none;
//       }

//       .selects {
//         .MuiSelect-select {
//           &:focus {
//             background-color: $c-white;
//           }
//           @include d-inline-flex-center;
//           padding-right: 0.5rem !important;
//           padding-left: 0.5rem !important;
//           border-radius: 2rem;

//           &::after {
//             content: '\F035D';
//            font-size: $fz-24px;
//             vertical-align: middle;
//             line-height: 100%;
//             font-family: 'Material Design Icons';
//           }

//           &[aria-expanded='true'] {
//             &::after {
//               content: '\F0360';
//             }
//           }
//         }

//         .MuiSelect-icon {
//           display: none;
//         }
//       }
//     }

//     &.theme-solid {
//       border-radius: 2rem;
//       width: 100%;

//       .selects {
//         .MuiSelect-select {
//           background-color: $c-gray-lightest;
//           color: $c-gray-primary;
//           border-radius: 1rem;
//           box-shadow: inset 5px 1px 5px 0px rgba(0, 0, 0, 0.16);
//         }

//         .MuiSelect-icon {
//           fill: $c-primary;
//         }
//       }
//     }

//     &.theme-underline {
//       border-radius: 0;
//       width: 100%;

//       .selects {
//         .MuiSelect-select {
//           color: $c-gray-primary;
//         }

//         .MuiSelect-icon {
//           fill: $c-primary;
//         }
//       }

//       &.select-filled {
//         .selects {
//           .MuiSelect-select {
//             color: $c-primary;
//           }
//         }
//       }

//       .MuiInput-underline:not(.Mui-disabled)::before {
//         border-bottom-color: $c-gray-lighter;

//         &.Mui-focused {
//           border-bottom-color: $c-primary;
//         }

//         &:hover {
//           border-bottom-color: $c-gray-primary;
//         }
//       }
//     }
//   }
// }
// End Select
// Start Chip Inputs
.chip-input-wrapper {
  @include d-flex-center;
  width: 100%;

  .MuiChip-root {
    margin-bottom: 0;
  }

  .chip-input {
    padding: 0.25rem 0.25rem;
    width: 100%;

    .MuiInputBase-input {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    >div {
      min-height: initial;
      margin-bottom: 0;
      min-height: 22px;
    }

    .WAMuiChipInput-chipContainer-14 {
      min-height: 30px;
    }

    .WAMuiChipInput-chip-26 {
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;

      @include rtl {
        margin-left: 0.25rem;
        margin-right: 0;
      }
    }

    .MuiChip-root {
      height: 27px;
    }

    &.theme-form-builder {
      background-color: $c-white;
      border-radius: 0.5rem;
      border: 1px solid $c-gray-secondary;

      :before,
      :after {
        display: none;
      }
    }
  }

  .WAMuiChipInput-underline-24:before {
    border-bottom: 2px solid $c-gray-light;
  }

  .WAMuiChipInput-underline-24:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid $c-primary;
  }
}

// End Chip Inputs
// Start Advance Search
.advance-search-btn {
  position: absolute;
  bottom: 130%;
  right: 0;

  @include rtl {
    left: 0;
    right: initial;
  }
}

.advance-search-wrapper {
  @include d-flex-column;
  padding: 41px 0.5rem;
}

// End Advance Search
// Start BXSlider
.bxslider-wrapper {
  @include d-flex;
  @include flex-v-center;
  width: 100%;
  margin-bottom: 1rem;

  .bxslide-arrow-decrement-wrapper,
  .bxslide-arrow-increment-wrapper {
    padding: 0.5rem;
  }

  .bxslider-items-wrapper {
    @include d-flex;
    width: 100%;
    min-height: 90px;
    justify-content: center;

    .bxslider-item {
      padding: 0 0.5rem;
      @include d-inline-flex;
      @include flex-v-center;
    }
  }
}

// End BXSlider
// Start Form Controls Label
.form-control-label.MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;

  .MuiFormControlLabel-label {
    color: $c-black;
  }
}

// End Form Controls Label
// ======================================================== End Global Assets
// ======================================================== Start Pages

// Start ViewTypes
.view-types-wrapper {
  @include d-inline-flex;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  background-color: $c-gray-lighter;

  .btns-view-type.MuiButton-root {
    outline: none;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0;
    background-color: transparent;
    border-radius: 0.5rem;

    &.active {
      background-color: $c-white;
      overflow: hidden;
    }

    .MuiButton-label {
      color: $c-gray-dark;
    }

    border: 0;

    // &:first-child {
    //   border-radius: 0.5rem 0 0 0.5rem;

    //   @include rtl {
    //     border-radius: 0 0.5rem 0.5rem 0;
    //   }
    // }

    // &:last-child {
    //   border-radius: 0 0.5rem 0.5rem 0;

    //   @include rtl {
    //     border-radius: 0.5rem 0 0 0.5rem;
    //   }
    // }

    &.active {
      .MuiButton-label {
        color: $c-primary;
      }

      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        border: 2px solid $c-primary;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        border-radius: 0.5rem;
      }
    }
  }
}

.c-red {
  color: $c-red;
}

// End ViewTypes

.styled-fieldset {
  border: 1px solid gainsboro;
  border-radius: 8px;
  min-height: 38px;
  padding: 5px;

  .styled-legend {
    width: fit-content;
    margin: 0;
    padding: 0px 4px;
    font-size: 12px;
    line-height: inherit;
    border: 0;
    color: black;
  }
}

// ======================================================== End Pages

// ======================================================== Start React Error Overlay

body>iframe[style*='2147483647'] {
  display: none;
}

// ======================================================== End React Error Overlay
.hipple-root {
  .MuiTuchipple-root {
    .bbt {
      display: block;
    }
  }
}

.react-toast-notifications__container {
  z-index: 1000000 !important;

  .css-18gu508-Content {
    word-wrap: break-word;
  }

  .css-nvf14r-ToastContainer {
    z-index: 1000000 !important;
  }
}

.globel-open-style {
  color: #36b37e;
  font-weight: bold;
}

.globel-closed-style {
  font-weight: bold;
  color: #df4912;
}

.globel-Yellow-style {
  font-weight: bold;
  color: #ffc400;
}

.globel-bold-style {
  font-weight: bold;
}

.globel-Gry-style {
  font-weight: bold;
  color: #828588;
}

.maqsamiframeClass {
  border-radius: 14px !important;
  border: 0 !important;
}

.globel-add-style {
  font-weight: bold;
  color: $c-primary;
}

.globel-remove-style {
  font-weight: bold;
  color: #df4912;
}

///////// start new design 2024 ///////

///////// end new design 2024 ///////