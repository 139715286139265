.step-card {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    padding: 20px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.13);

    &.active {
        box-shadow: unset;
        border: 1px solid #7F6944;
    }

    .card-check .checkbox-wrapper {
        padding: 0px !important;
    }
}